import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { concatPathWithQueryParams, removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'

export const getResultListSeoNavigatorLinkWithInitialSRLParam = (searchResult: SearchResult) =>
    removeUrlParameter(
        concatPathWithQueryParams(
            buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath),
            { isISRL: 'true' },
        ),
        'sfId',
    )
